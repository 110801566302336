import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const LogoGrid = ({ config }) => {
	const { heading, subheading, logos } = config;

	return (
		<section className="w-full py-12 bg-v2-cream">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center">
				{heading && (
					<Typography
						variant="subhead-lg"
						component="h2"
						className="mb-4 text-center text-v2-brown-darker">
						{heading}
					</Typography>
				)}

				{subheading && (
					<Typography variant="body" className="mb-8 text-center text-lg text-v2-brown">
						{subheading}
					</Typography>
				)}

				<div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-12">
					{logos.map((logo, index) => (
						<div key={index} className="flex h-24 items-center justify-center">
							{logo.linkUrl ? (
								<Clickable linkToInternal={logo.linkUrl}>
									<Image
										src={logo.imageSrc}
										alt={logo.altText || ''}
										width={120}
										height={80}
										className="max-h-20 w-auto object-contain"
									/>
								</Clickable>
							) : (
								<Image
									src={logo.imageSrc}
									alt={logo.altText || ''}
									width={120}
									height={80}
									className="max-h-20 w-auto object-contain"
								/>
							)}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

LogoGrid.propTypes = {
	config: PropTypes.shape({
		heading: PropTypes.string,
		subheading: PropTypes.string,
		backgroundColor: PropTypes.string,
		logos: PropTypes.array,
	}).isRequired,
};

export default LogoGrid;

import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const tableData = [
	{
		feature: 'Premium Fabric',
		us: { content: '95% premium MicroModal', status: 'good' },
		brandB: { content: 'Cheap viscose that pills', status: 'bad' },
		brandA: { content: 'Cheap, stinky polyester (plastic)', status: 'bad' },
		brandC: { content: 'Modal / Poly', status: 'neutral' },
		brandD: { content: 'Scratchy cotton', status: 'bad' },
	},
	{
		feature: 'Pouch',
		us: { content: '', status: 'good' },
		brandB: { content: '', status: 'good' },
		brandA: { content: '', status: 'bad' },
		brandC: { content: '', status: 'good' },
		brandD: { content: '', status: 'bad' },
	},
	{
		feature: 'Affordability',
		us: { content: 'From $17.99', status: 'good' },
		brandB: { content: '$34', status: 'bad' },
		brandA: { content: '$28', status: 'neutral' },
		brandC: { content: '$38', status: 'bad' },
		brandD: { content: '$5', status: 'good' },
	},
	{
		feature: 'First Pair Guarantee',
		us: { content: '', status: 'good' },
		brandB: { content: '30 days, w/ exceptions', status: 'neutral' },
		brandA: { content: '', status: 'bad' },
		brandC: { content: '', status: 'good' },
		brandD: { content: '101 days', status: 'neutral' },
	},
	{
		feature: 'Styles Available',
		us: { content: '5', status: 'neutral' },
		brandB: { content: '7', status: 'good' },
		brandA: { content: '3', status: 'bad' },
		brandC: { content: '6', status: 'neutral' },
		brandD: { content: '7', status: 'good' },
	},
	{
		feature: 'Sense of Humor',
		us: { content: 'Oh yeah', status: 'good' },
		brandB: { content: '', status: 'bad' },
		brandA: { content: 'Meh.', status: 'neutral' },
		brandC: { content: 'No. Definitely no', status: 'bad' },
		brandD: { content: '', status: 'bad' },
	},
];

const ComparisonTable = ({ config }) => {
	const getStatusIcon = (status) => {
		switch (status) {
			case 'good':
				return '✅';
			case 'bad':
				return '❌';
			case 'neutral':
				return '🟡';
			default:
				return '';
		}
	};

	const getStatusColor = (status) => {
		switch (status) {
			case 'good':
				return 'bg-green-100';
			case 'bad':
				return 'bg-red-100';
			case 'neutral':
				return 'bg-yellow-100';
			default:
				return 'bg-v2-off-white';
		}
	};

	return (
		<div className="container mx-auto md:p-4 p-2">
			<div className="py-2 md:py-4">
				{config?.heading && (
					<Typography variant="heading-md" component="h2">
						{config.heading}
					</Typography>
				)}
				{config?.subHeading && (
					<Typography variant="body" component="p">
						{config.subHeading}
					</Typography>
				)}
			</div>

			<div className="overflow-x-auto">
				<div className="inline-block min-w-full overflow-hidden">
					<table className="min-w-full leading-normal">
						<thead>
							<tr>
								<th className="sticky left-0 bg-gray-100 p-4 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
									Features
								</th>
								{['Shinesty', 'Brand A', 'Brand B', 'Brand C', 'Brand D'].map((brand) => (
									<th
										key={brand}
										className="p-4 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
										{brand}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="relative">
							{tableData.map((row, index) => (
								<tr key={index}>
									<td className="sticky left-0 bg-v2-off-white p-4 border-b border-gray-200 text-sm">
										{row.feature}
									</td>
									{['us', 'brandA', 'brandB', 'brandC', 'brandD'].map((brand) => (
										<td
											key={brand}
											className={`p-4 border-b border-gray-200 text-center leading-4 ${getStatusColor(
												row[brand].status,
											)} text-sm`}>
											{getStatusIcon(row[brand].status)}
											<br />
											{row[brand].content}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default ComparisonTable;

ComparisonTable.propTypes = {
	config: PropTypes.object,
};

import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const CalloutRow = ({ config }) => {
	const {
		heading,
		items,
		backgroundColor = 'bg-v2-brown-darker',
		textColor = 'text-v2-off-white',
	} = config;

	const headingTextColor =
		backgroundColor === 'bg-v2-off-white' ? 'text-v2-brown-darker' : textColor;

	return (
		<section className={classNames('w-full py-2 md:py-4', backgroundColor)}>
			{heading && (
				<Typography
					variant="heading-md"
					component="h2"
					className={classNames('mb-2 md:mb-8 text-center', headingTextColor)}>
					{heading}
				</Typography>
			)}

			<div className="mx-auto px-4">
				<div className="flex flex-wrap justify-center gap-2 md:gap-12">
					{items.map((item, index) => (
						<div
							key={index}
							className={classNames(
								'flex max-w-xs flex-col items-center p-4 text-center',
								'md:max-w-md md:flex-row md:text-left',
							)}>
							{item.iconSrc && (
								<div className="mb-4 h-12 w-12 flex-shrink-0 md:mb-0 md:mr-4">
									<Image
										src={item.iconSrc}
										alt={item.iconAlt || ''}
										width={64}
										height={64}
										className="h-full w-full object-contain"
									/>
								</div>
							)}
							<Typography variant="body" className={classNames('md:text-lg', textColor)}>
								{item.text}
							</Typography>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

CalloutRow.propTypes = {
	config: PropTypes.shape({
		heading: PropTypes.string,
		items: PropTypes.arrayOf(
			PropTypes.shape({
				iconSrc: PropTypes.string.isRequired,
				iconAlt: PropTypes.string,
				text: PropTypes.string.isRequired,
			}),
		).isRequired,
		backgroundColor: PropTypes.string,
		textColor: PropTypes.string,
	}).isRequired,
};

export default CalloutRow;

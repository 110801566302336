import Image from '/components/Image';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from '/utils/class-names';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';

const Zoom = dynamic(() => import('react-medium-image-zoom').then((mod) => mod.default), {
	ssr: false,
});

const DesktopCarousel = ({ images }) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [startIndex, setStartIndex] = useState(0);
	const sliderRef = useRef(null);

	useEffect(() => {
		setCurrentSlide(0);
		setStartIndex(0);
		if (sliderRef.current) {
			sliderRef.current.slickGoTo(0);
		}
	}, [images]);

	const maxThumbnails = 7;
	const hasMoreThumbnails = images.length > maxThumbnails;

	const handleScrollUp = () => {
		setStartIndex(Math.max(0, startIndex - 1));
	};

	const handleScrollDown = () => {
		setStartIndex(Math.min(images.length - maxThumbnails, startIndex + 1));
	};

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		beforeChange: (_, next) => setCurrentSlide(next),
	};

	return (
		<div className="hidden md:flex gap-4 pt-2 sticky top-28 h-min col-span-2">
			<div className="flex flex-col gap-2 relative">
				{hasMoreThumbnails && startIndex > 0 && (
					<button
						onClick={handleScrollUp}
						className="absolute left-1/2 -translate-x-1/2 p-2 rounded-full bg-white shadow-md hover:bg-gray-50 z-10">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M5 15l7-7 7 7"
							/>
						</svg>
					</button>
				)}

				{images.slice(startIndex, startIndex + maxThumbnails).map((image, index) => (
					<div
						key={startIndex + index}
						className={classNames(
							'cursor-pointer transition-opacity w-[100px]',
							currentSlide === startIndex + index ? 'opacity-100' : 'opacity-50 hover:opacity-75',
						)}
						onClick={() => sliderRef.current.slickGoTo(startIndex + index)}>
						<Image
							src={image.src}
							alt={image.altText || 'Product Thumbnail'}
							width={100}
							height={100}
							objectFit="contain"
						/>
					</div>
				))}

				{hasMoreThumbnails && startIndex < images.length - maxThumbnails && (
					<button
						onClick={handleScrollDown}
						className="absolute bottom-0 left-1/2 -translate-x-1/2 p-2 rounded-full bg-white shadow-md hover:bg-gray-50">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M19 9l-7 7-7-7"
							/>
						</svg>
					</button>
				)}
			</div>

			<div className="w-[85%] lg:w-[90%] px-10">
				<Slider {...settings} ref={sliderRef}>
					{images.map((image, index) => (
						<div key={index}>
							<Zoom overlayBgColorStart="rgb(253, 248, 242)" overlayBgColorEnd="rgb(253, 248, 242)">
								<div className="relative w-full h-[650px]">
									<Image
										src={image.src}
										alt={image.altText || 'Product Image'}
										layout="fill"
										objectFit="contain"
									/>
								</div>
							</Zoom>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

DesktopCarousel.propTypes = {
	images: PropTypes.array,
};

export default DesktopCarousel;

import Carousel from '/components/Carousel';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Stars from '/components/Stars';
import Typography from '/components/Typography';

const ReviewsCarousel = ({ config }) => {
	const { reviews } = config;
	return (
		<div className="max-w-sm md:max-w-6xl pb-6 md:pb-8 m-auto text-v2-brown-darker py-4">
			{config.heading && (
				<Typography
					className="text-2xl md:text-3xl font-semibold tracking-wide md:leading-9 leading-8 md:pb-6 pb-4 text-center gap-2 flex justify-center flex-wrap"
					component="h2">
					{config.heading}
				</Typography>
			)}
			{config.subheading && (
				<Typography className="text-center" component="p" variant="subhead-sm">
					{config.subheading}
				</Typography>
			)}
			<Carousel
				slidesToShow={5}
				infinite={true}
				autoplay={false}
				responsive={[
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
						},
					},
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 1,
						},
					},
				]}>
				{reviews.map((reviewItem, reviewItemIndex) => {
					return (
						<div className="md:p-4 h-full flex px-10" key={reviewItemIndex}>
							<div className="flex justify-center mb-4">
								<div className="w-[100px] h-[100px]">
									<Image
										alt={reviewItem.title}
										className="w-full h-full object-contain"
										width={100}
										height={100}
										src={reviewItem.imageSrc}
									/>
								</div>
							</div>

							<div className="flex justify-center mb-2">
								<Stars rating={5} />
							</div>

							<Typography className="font-semibold text-center mb-2" component="h3">
								{reviewItem.title}
							</Typography>

							<div className="flex-grow mb-4 overflow-y-auto">
								<Typography component="p" className="text-center">
									{reviewItem.body}
								</Typography>
							</div>

							<Typography className="font-semibold italic text-center mt-auto" component="p">
								{reviewItem.customer}
							</Typography>
						</div>
					);
				})}
			</Carousel>
		</div>
	);
};

ReviewsCarousel.propTypes = {
	config: PropTypes.object,
};

export default ReviewsCarousel;

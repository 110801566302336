import Image from '@/components/Image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Typography from '@/components/Typography';

const CollectionTiles = ({ config }) => {
	const { sectionHead, tiles } = config;

	return (
		<div className="max-w-7xl mx-auto bg-v2-off-white px-4 py-12">
			{sectionHead && (
				<Typography
					variant="heading-lg"
					component="h2"
					className="text-center mb-8 text-v2-brown-darker">
					{sectionHead}
				</Typography>
			)}

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
				{tiles.map((tile, index) => (
					<Link href={tile.href} key={index} className="group block cursor-pointer">
						<div className="rounded-lg overflow-hidden transition-transform duration-300 hover:scale-[1.02] cursor-pointer shadow-md pb-4">
							<Image
								src={tile.imageSrc}
								alt={tile.alt || ''}
								width={500}
								height={500}
								className="w-full object-cover aspect-[4/3]"
							/>
							<div className="mt-4 text-center">
								<Typography variant="subtitle-md" component="h3" className="mb-1">
									{tile.heading}
								</Typography>
								<Typography variant="body" className="text-gray-600">
									{tile.subHeading}
								</Typography>
							</div>
						</div>
					</Link>
				))}
			</div>
		</div>
	);
};

CollectionTiles.propTypes = {
	config: PropTypes.shape({
		sectionHead: PropTypes.string,
		tiles: PropTypes.arrayOf(
			PropTypes.shape({
				imageSrc: PropTypes.string.isRequired,
				alt: PropTypes.string,
				heading: PropTypes.string.isRequired,
				subHeading: PropTypes.string,
				href: PropTypes.string.isRequired,
			}),
		).isRequired,
	}).isRequired,
};

export default CollectionTiles;

import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const ProblemSolution = ({ config }) => {
	const { heading, subheading, imageSrc, items } = config;

	return (
		<div className="bg-v2-off-white px-4 py-12 text-v2-brown-darker">
			<div className="mx-auto max-w-7xl">
				<Typography component="h2" className="mb-4 text-center font-bold text-2xl md:text-3xl">
					{heading}
				</Typography>
				<Typography component="p" className="mb-8 text-center text-lg">
					{subheading}
				</Typography>

				<div className="flex flex-col md:flex-row md:gap-6">
					{imageSrc && (
						<div className="hidden md:block md:w-1/3 relative">
							<Image
								src={imageSrc}
								alt={heading || 'Problem solution illustration'}
								className="object-cover rounded-lg"
								layout="fill"
							/>
						</div>
					)}
					<div className={`${imageSrc ? 'md:w-2/3' : 'w-full'}`}>
						<div className="rounded-lg bg-white p-6 shadow-md">
							<Typography component="h3" className="mb-4 font-semibold text-xl">
								Severe Symptoms Include:
							</Typography>

							<div className="space-y-4">
								{items.map((item, index) => (
									<div
										key={index}
										className="grid grid-cols-1 gap-2 border-b border-gray-100 pb-3 md:grid-cols-2">
										<div className="flex items-center gap-2">
											<span className="text-xl text-red-500">❌</span>
											<Typography component="span">{item.problem}</Typography>
										</div>
										<div className="flex items-center gap-2">
											<span className="text-xl text-green-500">✅</span>
											<Typography component="span">{item.solution}</Typography>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ProblemSolution.propTypes = {
	config: PropTypes.object,
};

export default ProblemSolution;

import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const CategoryPreview = ({ config }) => {
	return (
		<div>
			<div className="py-4 px-4 max-w-7xl m-auto">
				<div className="sm:flex sm:items-baseline sm:justify-between">
					<Typography component="h2" font="moret" variant="subhead-lg">
						{config.heading}
					</Typography>
				</div>

				<div className="mt-6 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:grid-rows-2 gap-x-2 lg:gap-8">
					<a
						className="group relative overflow-hidden rounded-lg sm:row-span-2 cursor-pointer aspect-square"
						href={config.primaryHandle}>
						<Image
							src={config.primaryImageSrc}
							alt="First homepage image"
							layout="fill"
							objectFit="cover"
							objectPosition="center"
							className="object-cover object-center group-hover:opacity-75"
						/>
						<div className="absolute bottom-0 p-6 z-10">
							<div className="pb-3 grid">
								<Typography variant="subtitle-lg" component="h3" className="text-white">
									{config.primaryLinkText}
								</Typography>
								<Typography variant="subtitle-sm" component="h3" className="text-white">
									{config.primarySubheadText}
								</Typography>
							</div>
							<button className="bg-v2-off-white capitalize flex font-bold justify-center px-2 py-1 rounded-full text-gray-600 tracking-wide mr-auto">
								{config.primarybuttonText}
								<Icon className="ml-1 self-center" height="12px" name="caretRight" width="12px" />
							</button>
						</div>
					</a>
					<a
						className="group overflow-hidden rounded-lg relative sm:h-full cursor-pointer aspect-[10/5] md:aspect-[10/4] max-w-full"
						href={config.secondaryHandle}>
						<Image
							src={config.secondaryImageSrc}
							alt="Second homepage image"
							layout="fill"
							objectFit="cover"
							objectPosition="center"
							className="object-cover object-center group-hover:opacity-75 sm:inset-0 sm:h-full sm:w-full"
						/>
						<div className="absolute bottom-0 p-6 z-10">
							<div className="pb-3 grid">
								<Typography variant="subtitle-lg" component="h3" className="text-white">
									{config.secondaryLinkText}
								</Typography>
								<Typography variant="subtitle-sm" component="h3" className="text-white">
									{config.secondarySubheadText}
								</Typography>
							</div>
							<button className="bg-v2-off-white capitalize flex font-bold justify-center px-2 py-1 rounded-full text-gray-600 tracking-wide mr-auto">
								{config.secondarybuttonText}
								<Icon className="ml-1 self-center" height="12px" name="caretRight" width="12px" />
							</button>
						</div>
					</a>
					<a
						className="group overflow-hidden rounded-lg relative sm:h-full cursor-pointer aspect-[10/5] md:aspect-[10/4] max-w-full"
						href={config.tertiaryHandle}>
						<Image
							src={config.tertiaryImageSrc}
							alt="Thirt homepage image"
							layout="fill"
							objectFit="cover"
							objectPosition="center"
							className="object-cover object-center group-hover:opacity-75 sm:inset-0 sm:h-full sm:w-full"
						/>
						<div className="absolute bottom-0 p-6 z-10">
							<div
								className={classNames(
									'pb-3 grid',
									config.tertiaryTextClass ? config.tertiaryTextClass : 'text-white',
								)}>
								<Typography variant="subtitle-lg" component="h3">
									{config.tertiaryLinkText}
								</Typography>
								<Typography variant="subtitle-sm" component="h3">
									{config.tertiarySubheadText}
								</Typography>
							</div>
							<button className="bg-v2-off-white capitalize flex font-bold justify-center px-2 py-1 rounded-full text-gray-600 tracking-wide mr-auto">
								{config.tertiarybuttonText}
								<Icon className="ml-1 self-center" height="12px" name="caretRight" width="12px" />
							</button>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

CategoryPreview.propTypes = {
	config: PropTypes.object,
};

export default CategoryPreview;

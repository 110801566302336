import Image from 'next/image';
import Link from 'next/link';

import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const TextImageSplit = ({ config }) => {
	const {
		heading,
		bodyText,
		ctaText,
		ctaUrl,
		imageSrc,
		imageAlt,
		imagePosition = 'right',
	} = config;

	const isImageRight = imagePosition === 'right';

	return (
		<div className="bg-v2-off-white px-4 py-12 md:py-16 text-v2-brown-darker">
			<div className="mx-auto max-w-7xl">
				<div
					className={`flex flex-col ${
						isImageRight ? 'md:flex-row' : 'md:flex-row-reverse'
					} items-center gap-8 md:gap-12`}>
					<div className="flex w-full flex-col justify-center md:w-1/2">
						<Typography component="h2" variant="heading-lg" className="mb-4">
							{heading}
						</Typography>

						<Typography component="p" variant="body-lg" className="mb-6">
							{bodyText}
						</Typography>

						<Link href={ctaUrl}>
							<Typography
								component="span"
								variant="link"
								className="cursor-pointer text-v2-orange-burnt hover:text-v2-orange-burnt">
								{ctaText}
							</Typography>
						</Link>
					</div>

					<div className="w-full md:w-1/2">
						<div className="relative aspect-[4/3] w-full overflow-hidden rounded-lg">
							<Image
								src={imageSrc}
								alt={imageAlt}
								layout="fill"
								className="object-cover"
								sizes="(max-width: 768px) 100vw, 50vw"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

TextImageSplit.propTypes = {
	config: PropTypes.object,
};

export default TextImageSplit;

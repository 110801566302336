import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '../../Typography';

const CategoryTiles = ({ config }) => {
	const { tiles, headline } = config;

	return (
		<div className="w-full py-8">
			{headline && (
				<div className="text-center mb-6 px-4">
					<Typography className="hidden md:block" variant="subhead-md" component="h2" font="moret">
						{headline}
					</Typography>
					<Typography className="block md:hidden" variant="subhead-xs" component="h2" font="moret">
						{headline}
					</Typography>
				</div>
			)}

			<div className="grid grid-cols-2 gap-2 px-4 md:hidden">
				{tiles.map((tile, index) => (
					<Link href={tile.linkHref} key={index}>
						<a className="flex flex-col">
							<div className="relative aspect-[3/4] w-full overflow-hidden group">
								<Image
									src={tile.imageSrc}
									alt={tile.linkText || `Category ${index + 1}`}
									layout="fill"
									objectFit="cover"
									placeholder="blur"
									blurDataURL="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 400'%3E%3Crect width='400' height='400' fill='%23f8f8f8'/%3E%3C/svg%3E"
									className="transition-transform duration-300 group-hover:scale-105"
								/>
							</div>
							<div className="text-center mt-2 mb-4">
								<Typography variant="subtitle-lg" font="moret" component="h3">
									{tile.linkText}
								</Typography>
							</div>
						</a>
					</Link>
				))}
			</div>

			<div className="hidden md:block">
				<div className="flex flex-wrap justify-center gap-2">
					{tiles.map((tile, index) => (
						<div
							key={index}
							className="flex flex-col items-center w-[calc(25%-24px)] max-w-[300px]">
							<Link href={tile.linkHref}>
								<a className="block relative w-full aspect-[3/4] overflow-hidden group">
									<Image
										src={tile.imageSrc}
										alt={tile.title || `Category ${index + 1}`}
										layout="fill"
										objectFit="cover"
										placeholder="blur"
										blurDataURL="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 400'%3E%3Crect width='400' height='400' fill='%23f8f8f8'/%3E%3C/svg%3E"
										className="transition-transform duration-300 group-hover:scale-105"
									/>
								</a>
							</Link>
							<div className="text-center mt-4 mb-2">
								<Typography variant="subtitle-lg" font="moret" component="h3">
									{tile.linkText}
								</Typography>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

CategoryTiles.propTypes = {
	config: PropTypes.object,
};

export default CategoryTiles;

import DesktopCarousel from '/components/products/show/DesktopCarousel';
import ProductCarousel from '/components/ProductCarousel';
import ProductForm from '/components/products/show/ProductForm';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getCompleteProductById } from '/services/products';
import { getProductPageConfig } from '/services/static/pdp-config';
import { useEffect, useState } from 'react';

const ProductSingle = ({ config }) => {
	const reqConfig = config;
	const [product, setProduct] = useState();
	const [hasSwappedVariants, setHasSwappedVariants] = useState();
	const [joinedProducts, setJoinedProducts] = useState();
	const [packProducts, setPackProducts] = useState();
	const [pdpConfig, setPdpConfig] = useState({});

	useEffect(() => {
		const fetchProduct = async () => {
			const completeProduct = await getCompleteProductById(reqConfig.productId);
			setProduct(completeProduct?.product);
			const config = getProductPageConfig(completeProduct?.product);
			setPdpConfig(config);
			setJoinedProducts(completeProduct?.joinedProducts);
			setPackProducts(completeProduct?.packProducts);
			setHasSwappedVariants(completeProduct?.hasSwappedVariants);
		};

		if (config?.productId) {
			fetchProduct();
		}
	}, [config?.productId]);

	if (!product) {
		return null;
	}
	const processedImages = pdpConfig.imagesToShow
		? product.images.slice(0, pdpConfig.imagesToShow)
		: product.images;
	return (
		<div className="container max-w-7xl mb-12 w-screen md:w-auto px-4 md:px-0">
			{config.heading && (
				<Typography className="text-center" component="h1" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			{product && (
				<div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 lg:p-6 rounded-lg bg-v2-off-white relative">
					<div className="text-center mt-4 lg:mt-0 col-span-2 md:hidden">
						<ProductCarousel product={product} />
					</div>
					<div className="sticky top-0 col-span-2">
						<DesktopCarousel images={processedImages} />
					</div>
					<div>
						<ProductForm
							hasSwappedVariants={hasSwappedVariants}
							hideCrossShoppable={config.hideCrossShoppable}
							isQuickShop={true}
							joinedProducts={joinedProducts}
							showProductLink={true}
							location="Quick Shop"
							packProducts={packProducts}
							product={product}
							pdpConfig={pdpConfig}
							showExtraFormInfo={false}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

ProductSingle.propTypes = {
	config: PropTypes.object,
};

export default ProductSingle;

import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { subscribeEmail } from '/services/email-list';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useRef, useState } from 'react';

const NewsletterSignup = ({ config }) => {
	const {
		heading,
		subheading,
		discountText,
		buttonText,
		termsText,
		backgroundColor = 'bg-amber-300',
	} = config;

	const [email, setEmail] = useState('');
	const [formState, setFormState] = useState({});
	const [termsAccepted, setTermsAccepted] = useState(false);
	const inputRef = useRef(null);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const submitForm = async (event) => {
		event.preventDefault();

		if (!termsAccepted) {
			setFormState({
				status: 'error',
				message: 'Please accept the terms to continue',
			});
			return;
		}

		const response = await subscribeEmail({
			email,
			listKeys: ['landing-page'],
			executeRecaptcha,
			recaptchaKey: 'footer',
			successMessage: 'Successfully subscribed!',
		});

		setFormState(response);
	};

	return (
		<div className={`${backgroundColor} px-4 py-12 md:py-16`}>
			<div className="mx-auto max-w-3xl text-center">
				<Typography
					component="h2"
					variant="heading-md"
					className="mb-6 uppercase text-v2-brown-darker">
					{heading}
				</Typography>

				<div className="mb-8 flex flex-wrap items-center justify-center text-v2-brown-darker">
					<Typography component="span" variant="heading-lg" className="mr-4">
						Get
					</Typography>
					<span className="my-2 inline-block rounded-full bg-white px-6 py-2">
						<Typography component="span" variant="heading-lg" className="font-bold">
							{discountText}
						</Typography>
					</span>
					<Typography component="span" variant="heading-lg" className="ml-4">
						{subheading}
					</Typography>
				</div>

				<form onSubmit={submitForm}>
					<div className="mb-6">
						<div className="flex overflow-hidden rounded-lg border border-v2-brown-darker">
							<input
								ref={inputRef}
								type="email"
								placeholder="Your email"
								className="w-full flex-1 border-none bg-white px-4 py-3 text-lg focus:outline-none"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<button type="submit" className="bg-primary px-6 py-3 font-bold text-white">
								{buttonText}
							</button>
						</div>
					</div>

					<div className="flex items-center justify-center mb-4">
						<input
							type="checkbox"
							className="mr-2 h-5 w-5 cursor-pointer"
							checked={termsAccepted}
							onChange={() => setTermsAccepted(!termsAccepted)}
						/>
						<Typography component="span" variant="body" className="text-v2-brown-darker">
							{termsText}
						</Typography>
					</div>

					{formState && formState.message && (
						<div className="flex justify-center mb-4">
							<Typography
								className={classNames(
									'bg-white italic px-4 py-2 rounded-full',
									formState.status === 'success' ? 'text-green-700' : 'text-red-600',
								)}>
								{formState.message}
							</Typography>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

NewsletterSignup.propTypes = {
	config: PropTypes.object,
};

export default NewsletterSignup;

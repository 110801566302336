import ProductListingSS from '/components/collections/ProductListingSS';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getCollectionProducts } from '/services/searchspring/search';
import { logTryCatch } from '/utils/logging';
import { useEffect, useState } from 'react';

const CollectionStandard = ({ config, productCardClassNames }) => {
	let pageSize = 12;
	const [searchspringResponse, setSearchspringResponse] = useState();

	useEffect(() => {
		async function getProducts() {
			try {
				const response = await getCollectionProducts({
					isCollection: true,
					collectionHandle: config.collectionHandle,
					resultsPerPage: pageSize,
					page: 1,
				});
				setSearchspringResponse(response);
			} catch (error) {
				logTryCatch(error);
			}
		}

		if (config.collectionHandle) {
			getProducts();
		}
	}, [config.collectionHandle, pageSize]);

	try {
		if (typeof config.pageSize === 'string') {
			pageSize = parseInt(config.pageSize);
		}
	} catch (e) {
		logTryCatch('could not parse page size');
	}

	return (
		<div className="container max-w-7xl mb-4">
			{config.heading && (
				<Typography className="text-center" component="h1" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			{config.collectionHandle && searchspringResponse && (
				<ProductListingSS
					searchspringResponse={searchspringResponse}
					facets={{}}
					pageSize={pageSize}
					pageType="landing"
					productCardClassNames={productCardClassNames}
					productCount={pageSize}
				/>
			)}
		</div>
	);
};

CollectionStandard.propTypes = {
	config: PropTypes.object,
	productCardClassNames: PropTypes.string,
};

export default CollectionStandard;
